import React from 'react';

import { Row, Col } from 'antd';

import {GatsbyImage} from 'gatsby-plugin-image';
import {IGatsbyImageData} from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import {HireTypes, HireTypesArr} from '../../contracts/page';

import './HireTypes.scss';

export interface Props {
	hireTypes: HireTypes;
	location: Location;
}

export const Hires = (props: Props) => {
	const hireTypes = props.hireTypes;
	return (
		<Row type="flex" className="c_hire-types page-gutter" id="hireCategories">
			{hireTypes.hireType.length && hireTypes.hireType.map((item: HireTypesArr, itemIndex: number) => {
				const hireImg: IGatsbyImageData | null = (
					item &&
					item.hireImage.localFile &&
					item.hireImage.localFile.childImageSharp &&
					item.hireImage.localFile.childImageSharp.gatsbyImageData
				) ? item.hireImage.localFile.childImageSharp.gatsbyImageData : null;
				return (
					<Col key={itemIndex} className="display-flex" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
						<a href={item.hireLink?.link} title={item.typeOfHire + ' Hire'}>
							{(hireImg !== null) ? (
								<GatsbyImage image={hireImg} alt={item.typeOfHire + ' Hire'}/>
							) : ''}
							<div className="c_hire-types__content-wrap">
								<p className="c_hire-types__title">{item.typeOfHire} Hire</p>
								<p className="c_hire-types__content" dangerouslySetInnerHTML={{__html: item.hireContent}}/>
							</div>
						</a>
					</Col>
				);
			})}
		</Row>
	);
};

export default Hires;
