import React from 'react';

import {graphql} from 'gatsby';

import { Row, Col } from 'antd';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import {Page} from '../contracts/page';

import {IGatsbyImageData} from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import BookingForm from '../components/Booking';
import ColourBlock from '../components/ColourBlock';
import ContentBlockHtml from '../components/ContentBlock';
import Cta from '../components/Ctas';
import FaqBlock from '../components/Faqs';
import Hero from '../components/Hero';
import Hires from '../components/HireTypes';
import MeetTeam from '../components/MeetTeam';
import ReviewsBlock from '../components/Reviews';
import TimelineBlock from '../components/Timeline';

import '../styles/about.scss';

export interface Props {
	data: {
		wpPage: Page;
	};
	location: Location;
}

export const About = (props: Props) => {
	const page = props.data.wpPage;
	const fluid: IGatsbyImageData | null = (
		props.data &&
		page &&
		page.heroBanner &&
		page.heroBanner.bannerImage &&
		page.heroBanner.bannerImage.localFile &&
		page.heroBanner.bannerImage.localFile.childImageSharp &&
		page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData
	) ? page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData : null;
	const bannerFluid: IGatsbyImageData | null = (
		props.data &&
		page &&
		page.bannerContentBlock &&
		page.bannerContentBlock.bannerContentImage &&
		page.bannerContentBlock.bannerContentImage.localFile &&
		page.bannerContentBlock.bannerContentImage.localFile.childImageSharp &&
		page.bannerContentBlock.bannerContentImage.localFile.childImageSharp.gatsbyImageData
	) ? page.bannerContentBlock.bannerContentImage.localFile.childImageSharp.gatsbyImageData : null;
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true
				}
			}
		]
	};
	return (
		<Layout location={props.location}>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Row type="flex">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} id="primary" className="content-area">
					<div className="about">
						<div className="top-banner">
							{(page.heroBanner && page.heroBanner.showBanner === 'yes' && fluid !== null) && (
								<Hero location={props.location} heroBanner={page.heroBanner} fluid={fluid}/>
							)}
							{(page.bookNowBlock && page.bookNowBlock.showBookNowBlock === 'yes') ? (
								<BookingForm location={props.location} isVehicle={true}/>
							) : ''}
						</div>
						{(page.timeline && page.timeline.timeline && page.timeline.timeline.length) ? (
							<TimelineBlock location={props.location} timelineArr={page.timeline}/>
						) : ''}
						{(page.meetTheTeam && page.meetTheTeam.teamRepeater && page.meetTheTeam.teamRepeater.length) ? (
							<MeetTeam location={props.location} meetTheTeam={page.meetTheTeam}/>
						) : ''}
						{(page.reviews.showReviews === 'yes' && page.reviews && page.reviews.reviews && page.reviews.reviews.length) ? (
							<ReviewsBlock location={props.location} reviews={page.reviews} settings={settings}/>
						) : ''}
						{(page.hireTypes && page.hireTypes.hireType && page.hireTypes.hireType.length) ? (
							<Hires location={props.location} hireTypes={page.hireTypes}/>
						) : ''}
						{(page.ctas && page.ctas.ctas && page.ctas.ctas.length) ? (
							<Cta location={props.location} ctas={page.ctas} welfare={false}/>
						) : ''}
						{(page.bannerContentBlock && page.bannerContentBlock.showBannerContentBlock === 'yes' && bannerFluid !== null) ? (
							<ColourBlock location={props.location} bannerContentBlock={page.bannerContentBlock} bannerFluid={bannerFluid}/>
						) : ''}
						{(page.faqs.showFaqs === 'yes' && page.faqs && page.faqs.faqs && page.faqs.faqs.length) ? (
							<FaqBlock location={props.location} showHeading={true} faqs={page.faqs} bgWhite={true}/>
						) : ''}
						{(page.contentBlock && page.contentBlock.showContentBlock === 'yes') ? (
							<ContentBlockHtml location={props.location} contentBlock={page.contentBlock}/>
						) : ''}
					</div>
				</Col>
			</Row>
		</Layout>
	);
};

export default About;

export const query = graphql`
	query($id: String!) {
      wpPage(id: { eq: $id }) {
      	title
      	content
        slug
        id
        bookNowBlock {
          showBookNowBlock
        }
		bannerContentBlock {
		  bannerContentBackgroundColour
		  bannerContentContent
		  bannerContentCtaLink
		  bannerContentCtaText
		  bannerContentShowCta
		  bannerContentSubtitle
		  bannerContentSubtitleColour
		  bannerContentTitle
		  bannerContentTitleColour
		  fieldGroupName
		  showBannerContentBlock
		  bannerContentImage {
		    altText
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
		contentBlock {
		  contentContent
		  contentSubtitle
		  contentTitle
		  fieldGroupName
		  showContentBlock
		}
		ctas {
		  ctas {
			fieldGroupName
			ctaTitle
			ctaIcon {
			  altText
			  localFile {
			    publicURL
			    childImageSharp {
				  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
			    }
			  }
		    }
			ctaLink {
			  target
			  title
			  url
			}
		  }
		  fieldGroupName
		}
		faqs {
		  faqs {
			question
			fieldGroupName
			answer
		  }
		  showFaqs
		  fieldGroupName
		}
		heroBanner {
		  bannerContent
		  bannerLayout
		  bannerTextColour
		  fieldGroupName
		  bannerTitle
		  showBanner
		  bannerImage {
			altText
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
		hireTypes {
		  fieldGroupName
		  hireType {
			fieldGroupName
			hireContent
			typeOfHire
			hireLink {
			  ... on WpPage {
				link
			  }
			}
			hireImage {
			  altText
			  caption
			  description
			  fileSize
			  localFile {
				childImageSharp {
				  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
				}
			  }
			}
		  }
		}
		reviews {
		  showReviews
		  reviews {
			reviewContent
			reviewName
			reviewStars
		  }
		}
		meetTheTeam {
		  teamRepeater {
			teamName
			teamPosition
			teamImage {
			  altText
			  localFile {
			    childImageSharp {
				  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
			    }
			  }
			}
		  }
		}
		timeline {
		  timeline {
			timelineContent
			timelineTitle
		  }
		}
		seo {
		  metaDesc
		  metaKeywords
		  metaRobotsNoindex
		  metaRobotsNofollow
		  opengraphAuthor
		  opengraphDescription
		  opengraphModifiedTime
		  opengraphPublishedTime
		  opengraphPublisher
		  opengraphSiteName
		  opengraphTitle
		  opengraphType
		  opengraphUrl
		  readingTime
		  title
		  twitterTitle
		  schema {
			articleType
			pageType
			raw
		  }
		  twitterDescription
		  twitterImage {
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
      }
    }
`;
