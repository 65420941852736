import React from 'react';

import { Row, Col } from 'antd';

import {CtaArr, Ctas} from '../../contracts/page';

import {GatsbyImage} from 'gatsby-plugin-image';
import {IGatsbyImageData} from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import './Ctas.scss';

export interface Props {
	ctas: Ctas;
	location: Location;
	welfare: boolean;
}

export const Cta = (props: Props) => {
	const ctas    = props.ctas;
	const welfare = props.welfare;
	return (
		<Row type="flex" className="c_ctas page-gutter">
			{ctas.ctas.length && ctas.ctas.map((item: CtaArr, itemIndex: number) => {
				const fluid: IGatsbyImageData | null = (
					item.ctaIcon &&
					item.ctaIcon.localFile &&
					item.ctaIcon.localFile.childImageSharp &&
					item.ctaIcon.localFile.childImageSharp.gatsbyImageData
				) ? item.ctaIcon.localFile.childImageSharp.gatsbyImageData : null;
				return (
					<Col key={itemIndex} className="display-flex align-center" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
						{(welfare) ? (
							<a href={item.ctaLink?.url} title={item.ctaLink?.title} target={item.ctaLink?.target}>
								{(fluid !== null) ? (
									<div>
										<GatsbyImage image={fluid} alt={item.ctaTitle} />
										<span className="c_ctas__title">{item.ctaTitle}</span>
										<p className="c_ctas__content">{item.ctaContent}</p>
									</div>
								) : (
									<div>
										<img src={item.ctaIcon.localFile?.publicURL} alt={item.ctaTitle} />
										<span className="c_ctas__title">{item.ctaTitle}</span>
										<p className="c_ctas__content">{item.ctaContent}</p>
									</div>
								)}
							</a>
						) : (
							<a href={item.ctaLink?.url} title={item.ctaLink?.title} target={item.ctaLink?.target}>
								{(fluid !== null) ? (
									<p className="c_ctas__title"><GatsbyImage image={fluid} alt={item.ctaTitle} />{item.ctaTitle}</p>
								) : (
									<p className="c_ctas__title"><img src={item.ctaIcon.localFile?.publicURL} alt={item.ctaTitle} />{item.ctaTitle}</p>
								)}
							</a>
						)}
					</Col>
				);
			})}
		</Row>
	);
};

export default Cta;
