import React from 'react';

import { Row, Col } from 'antd';

import {Timeline, TimelineArr} from '../../contracts/page';

import './Timelines.scss';

export interface Props {
	timelineArr: Timeline;
	location: Location;
}

export const TimelineBlock = (props: Props) => {
	const timelineArr = props.timelineArr;
	return (
		<Row type="flex" className="c_timeline page-gutter">
			{timelineArr.timeline.length && timelineArr.timeline.map((item: TimelineArr, itemIndex: number) => {
				return (
					<Col key={itemIndex} className="display-flex" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
						<div className="c_timeline__item">
							<span className="c_timeline__line">&nbsp;</span>
							<p className="c_timeline__title">{item.timelineTitle}</p>
							<div className="c_timeline__content" dangerouslySetInnerHTML={{__html: item.timelineContent}}/>
						</div>
					</Col>
				);
			})}
		</Row>
	);
};

export default TimelineBlock;
