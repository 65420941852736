import React from 'react';

import { Row, Col } from 'antd';

import {BannerContentBlock} from '../../contracts/page';
import {GatsbyImage} from 'gatsby-plugin-image';
import {IGatsbyImageData} from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import './ColourBlock.scss';

export interface Props {
	bannerContentBlock: BannerContentBlock;
	bannerFluid: IGatsbyImageData;
	location: Location;
}

export const ColourBlock = (props: Props) => {
	const bannerContentBlock = props.bannerContentBlock;
	const bannerFluid 		 = props.bannerFluid;
	return (
		<Row type="flex" className="c_colour-block page-gutter">
			<Col className={'md-order-2 bg-' + bannerContentBlock.bannerContentBackgroundColour} xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
				<div className={'c_colour-block__wrap bg-' + bannerContentBlock.bannerContentBackgroundColour}>
					<p className={'c_colour-block__title text-' + bannerContentBlock.bannerContentTitleColour}>
						{bannerContentBlock.bannerContentTitle}
					</p>
					<p className={'c_colour-block__subtitle text-' + bannerContentBlock.bannerContentSubtitleColour}>
						{bannerContentBlock.bannerContentSubtitle}
					</p>
					<div className={'c_colour-block__content text-' + bannerContentBlock.bannerContentTitleColour}
						 dangerouslySetInnerHTML={{__html: bannerContentBlock.bannerContentContent}}/>
					<a href={bannerContentBlock.bannerContentCtaLink}
					   className={'c_colour-block__cta btn btn-primary cta-' + bannerContentBlock.bannerContentSubtitleColour}>
						{bannerContentBlock.bannerContentCtaText}
					</a>
				</div>
			</Col>
			<Col className="md-order-1" xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
				<GatsbyImage className="c_colour-block__img" image={bannerFluid}
					   alt={bannerContentBlock.bannerContentTitle}
					   title={bannerContentBlock.bannerContentTitle} />
			</Col>
		</Row>
	);
};

export default ColourBlock;
