import React from 'react';

import { Row, Col } from 'antd';

import {MeetTheTeam, TeamRepeaterArr} from '../../contracts/page';

import {GatsbyImage} from 'gatsby-plugin-image';
import {IGatsbyImageData} from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import './MeetTheTeam.scss';

export interface Props {
	meetTheTeam: MeetTheTeam;
	location: Location;
}

export const MeetTeam = (props: Props) => {
	const meetTheTeam = props.meetTheTeam;
	return (
		<Row type="flex" className="c_meetTheTeam page-gutter">
			<p className="c_meetTheTeam__heading">Meet the team</p>
			{meetTheTeam.teamRepeater.length && meetTheTeam.teamRepeater.map((item: TeamRepeaterArr, itemIndex: number) => {
				const teamImg: IGatsbyImageData | null = (
					item &&
					item.teamImage.localFile &&
					item.teamImage.localFile.childImageSharp &&
					item.teamImage.localFile.childImageSharp.gatsbyImageData
				) ? item.teamImage.localFile.childImageSharp.gatsbyImageData : null;
				return (
					<Col key={itemIndex} className="display-flex align-center" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
						<div className="c_meetTheTeam__item">
							{(teamImg !== null) ? (
								<GatsbyImage image={teamImg} alt={item.teamName}/>
							) : ''}
							<p className="c_meetTheTeam__name">{item.teamName}</p>
							<p className="c_meetTheTeam__position">{item.teamPosition}</p>
						</div>
					</Col>
				);
			})}
		</Row>
	);
};

export default MeetTeam;
